import { createRef, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import PageContainer from "../../components/page/PageContainer";
import { VHOST } from "../../constants/ovenmedia";
import { OvenMediaAppContext, OvenMediaAppProvider, OvenMediaAppPublisher, OvenMediaStreamContext } from "../../interfaces/context";
import { useOvenMediaAllStats, useOvenMediaContexts } from "../../store/context/hooks";
import TableApplication, { TableApplicationData } from "./container/table-application";

import { CustomCheckBox, CustomCheckBoxRef, PopupButtonType, PopupIconType, showPopup, Touchable } from "@kalyzee/kast-app-web-components";
import { toastError, toastSuccess } from "../../helpers/toast";
import { useSocketAppDispatch } from "../../hooks/app";
import { useMultiServer } from "../../store/session/hooks";
import { socketAddRedirection, socketPullStream } from "../../store/socket/actions";
import styles from "./application.module.css";
import ApplicationNetworkContainer from "./container/network";

const ApplicationPage = () => {
  const socketDispatch = useSocketAppDispatch();
  const { app } = useParams();
  const allStats = useOvenMediaAllStats();
  const contexts = useOvenMediaContexts();
  const multiServer = useMultiServer();
  const [showNetworkDetails, setShowNetworkDetails] = useState(false);
  const applications = useMemo(() => {
    const applications = new Set<string>();
    contexts?.forEach((context) => {
      if (!context) return;
      if (!context.vhosts[VHOST]) return;
      const apps = Object.keys(context.vhosts[VHOST].apps).sort((a, b) => a.localeCompare(b));
      apps.forEach((a) => applications.add(a));
    });
    return Array.from(applications);
  }, [contexts]);

  const { data, providers, publishers } = useMemo(() => {
    let data: TableApplicationData[] = [];
    const publishers = new Set<OvenMediaAppPublisher>();
    const providers = new Set<OvenMediaAppProvider>();

    const ovtsAssociated: TableApplicationData["ovts"] = [];

    contexts?.forEach((context) => {
      let appContext: OvenMediaAppContext | undefined = app ? context?.vhosts[VHOST]?.apps?.[app] : undefined;
      let stats = allStats?.[context.server._id];

      if (app) {
        const currAppContext = appContext;
        const currAppStats = app ? stats?.vhosts[VHOST]?.apps?.[app] : undefined;
        if (currAppContext) {
          const streams = Object.keys(currAppContext?.streams);
          streams.forEach((stream) => {
            const streamContext: OvenMediaStreamContext = currAppContext.streams?.[stream];
            if (streamContext) {
              const ovts: TableApplicationData["ovts"] = [];
              if (streamContext.live && streamContext.input?.sourceType?.toLocaleLowerCase() !== "ovt") {
                contexts.forEach((c) => {
                  const currStreamContext = c.vhosts[VHOST]?.apps?.[app]?.streams[stream];
                  if (currStreamContext && currStreamContext.input?.sourceType.toLocaleLowerCase() === "ovt") {
                    const ovt = {
                      context: c,
                      app: c.vhosts[VHOST].apps[app],
                      stream: currStreamContext,
                      stats: allStats?.[c.server._id]?.vhosts[VHOST]?.apps?.[app]?.streams[stream],
                    };
                    ovtsAssociated.push(ovt);
                    ovts.push(ovt);
                  }
                });
              }
              data.push({
                context,
                app: currAppContext,
                stream: streamContext,
                stats: currAppStats?.streams[stream],
                ovts: ovts,
              });
            }
          });
        }
        context?.vhosts[VHOST]?.apps?.[app].providers?.forEach((p) => providers.add(p));
        context?.vhosts[VHOST]?.apps?.[app].publishers?.forEach((p) => publishers.add(p));
      } else if (context?.vhosts[VHOST]?.apps) {
        const apps = Object.keys(context?.vhosts[VHOST]?.apps);
        apps.forEach((app) => {
          const appContext: OvenMediaAppContext | undefined = app ? context?.vhosts[VHOST]?.apps?.[app] : undefined;
          const currAppStats = app ? stats?.vhosts[VHOST]?.apps?.[app] : undefined;
          if (appContext) {
            const streams = Object.keys(appContext?.streams);
            streams.forEach((stream) => {
              const streamContext: OvenMediaStreamContext = appContext.streams?.[stream];
              if (streamContext) {
                const ovts: TableApplicationData["ovts"] = [];
                if (streamContext.live && streamContext.input?.sourceType.toLocaleLowerCase() !== "ovt") {
                  contexts.forEach((c) => {
                    const currStreamContext = c.vhosts[VHOST]?.apps?.[app]?.streams[stream];
                    if (currStreamContext && currStreamContext.input?.sourceType.toLocaleLowerCase() === "ovt") {
                      const ovt = {
                        context: c,
                        app: c.vhosts[VHOST].apps[app],
                        stream: currStreamContext,
                        stats: allStats?.[c.server._id]?.vhosts[VHOST]?.apps?.[app]?.streams[stream],
                      };
                      ovtsAssociated.push(ovt);
                      ovts.push(ovt);
                    }
                  });
                }
                data.push({
                  context,
                  app: appContext,
                  stream: streamContext,
                  stats: currAppStats?.streams[stream],
                  ovts: ovts,
                });
              }
            });
          }
        });
      }
    });

    data = data.filter((d) => {
      return !ovtsAssociated.find((o) => o.context === d.context && o.app === d.app && o.stream === d.stream);
    });

    return { data, providers: Array.from(providers), publishers: Array.from(publishers) };
  }, [contexts, allStats]);

  return (
    <PageContainer title={"Application" /* TRANSLATION */} subtitle={app} loading={!contexts?.length}>
      <div style={{ width: "100%" }}>
        <Touchable
          className={styles.pullButton}
          onPress={() => {
            const inputStreamRef = createRef<HTMLInputElement>();
            const inputUrlRef = createRef<HTMLInputElement>();
            const inputForceRef = createRef<CustomCheckBoxRef>();
            const inputPersistentRef = createRef<CustomCheckBoxRef>();
            const inputNoInputFailoverRef = createRef<HTMLInputElement>();
            const inputUnusedStreamDeletionTimeoutMSRef = createRef<HTMLInputElement>();
            let serversButtonContainerRef: (React.RefObject<HTMLDivElement> | null)[] = [];
            const servers = contexts?.map((c) => c.server?._id).filter((id) => id);
            let server = servers[0];
            let appsButtonContainerRef: (React.RefObject<HTMLDivElement> | null)[] = [];
            let application = app ?? applications[0];
            showPopup(
              {
                title: "Pull un flux ?",
                iconTitle: PopupIconType.INFO,
                content: (
                  <div>
                    <div className={styles.popupTitle}>Renseigner l'url OVT ou RTSP d'où sera pull le stream.</div>
                    <div className={styles.popupSubtitle}>Url*:</div>
                    <input placeholder="ovt://streaming.dev.kalyzee.com/app/stream:9000" className={styles.popupInput} ref={inputUrlRef} />
                    <div style={{ marginBottom: "5px" }} />
                    <div className={styles.popupTitle}>Où voulez-vous que le flux soit pull ?.</div>
                    <div className={styles.popupSelectionContainer}>
                      <div className={styles.popupSubtitle}>Server:</div>
                      <div className={styles.popupSelectionList}>
                        {servers.map((t, i) => {
                          const ref = createRef<HTMLDivElement>();
                          const currClasses = [styles.popupSelectionButton];
                          if (t === server) currClasses.push(styles.popupSelectionButtonSelected);
                          serversButtonContainerRef[i] = ref;
                          return (
                            <div key={t} ref={ref} className={currClasses.join(" ")}>
                              <Touchable
                                onPress={() => {
                                  server = t;
                                  serversButtonContainerRef.forEach((r, j) => {
                                    const classList = r?.current?.classList;
                                    if (i === j) classList?.add(styles.popupSelectionButtonSelected);
                                    else classList?.remove(styles.popupSelectionButtonSelected);
                                  });
                                }}
                              >
                                {t}
                              </Touchable>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                    {
                      !app ? (
                        <>
                        <div style={{ marginBottom: "5px" }} />
                        <div className={styles.popupSelectionContainer}>
                          <div className={styles.popupSubtitle}>Apps:</div>
                          <div className={styles.popupSelectionList}>
                            {applications.map((t, i) => {
                              const ref = createRef<HTMLDivElement>();
                              const currClasses = [styles.popupSelectionButton];
                              if (t === application) currClasses.push(styles.popupSelectionButtonSelected);
                              appsButtonContainerRef[i] = ref;
                              return (
                                <div key={t} ref={ref} className={currClasses.join(" ")}>
                                  <Touchable
                                    onPress={() => {
                                      application = t;
                                      appsButtonContainerRef.forEach((r, j) => {
                                        const classList = r?.current?.classList;
                                        if (i === j) classList?.add(styles.popupSelectionButtonSelected);
                                        else classList?.remove(styles.popupSelectionButtonSelected);
                                      });
                                    }}
                                  >
                                    {t}
                                  </Touchable>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                        </>
                      ) : null
                    }

                    <div style={{ marginBottom: "5px" }} />
                    <div className={styles.popupSubtitle}>Stream name*:</div>
                    <input className={styles.popupInput} ref={inputStreamRef} />

                    <div style={{ marginBottom: "5px" }} />
                    <div className={styles.popupTitle}>Options : </div>
                    <div className={styles.popupSubtitle}>force:</div>
                    <CustomCheckBox style={{ border: 'unset'}} ref={inputForceRef} className={styles.popupInput} />
                    <div className={styles.popupSubtitle}>persistent:</div>
                    <CustomCheckBox style={{ border: 'unset'}} ref={inputPersistentRef} className={styles.popupInput} />
                    <div className={styles.popupSubtitle}>noInputFailoverTimeoutMs:</div>
                    <input type="number" className={styles.popupInput} ref={inputNoInputFailoverRef} />
                    <div className={styles.popupSubtitle}>unusedStreamDeletionTimeoutMs:</div>
                    <input type="number" defaultValue={10000} className={styles.popupInput} ref={inputUnusedStreamDeletionTimeoutMSRef} />
                  </div>
                ),
                buttons: [
                  { type: PopupButtonType.CANCEL, element: "Annuler" },
                  {
                    type: PopupButtonType.VALIDATE,
                    element: "Valider" /* TRANSLATION */,
                    onClick: async () => {
                      const url = inputUrlRef.current?.value?.trim();
                      const stream = inputStreamRef.current?.value;
                      const force = inputForceRef.current?.isChecked();
                      const persistent = inputPersistentRef.current?.isChecked();
                      const noInputFailoverTimeoutMs = Number(inputNoInputFailoverRef.current?.value);
                      const unusedStreamDeletionTimeoutMs = Number(inputUnusedStreamDeletionTimeoutMSRef.current?.value);
                      let urlObj: URL | undefined;

                      if (!stream) {
                        toastError(`Le nom du stream saisie ne semble pas valide`);
                        return true;
                      }

                      try {
                        urlObj = new URL(url ?? "");
                      } catch (_) {}

                      if (!urlObj) {
                        toastError(`L'url saisie ne semble pas valide`);
                        return true;
                      } else if (urlObj.protocol !== "ovt:" && urlObj.protocol !== "rtsp:") {
                        toastError(`L'url saisie n'est pas une url OVT ou RTSP`);
                        return true;
                      }

                      const result = await socketDispatch(
                        socketPullStream({ serverId: server, data: { appName: application, streamName: stream, url: url!, force, persistent, noInputFailoverTimeoutMs, unusedStreamDeletionTimeoutMs } })
                      );
                      if (result.error) {
                        toastError(`Impossible de pull le stream. Error : ${result.error}`);
                      } else {
                        toastSuccess(`Votre flux est maintenant redirigé sur : ${urlObj.href}`);
                      }
                      return true;
                    },
                  },
                ],
                enableBackdropDismiss: false,
                enableCloseButton: false,
              },
              undefined,
              { displayHover: true }
            );
          }}
        >
          Pull un stream
        </Touchable>
        {data?.length ? (
          <>
            <div className={styles.sectionTitle}>{"ACTIVE LIVE STREAMS" /* TRANSLATION */}</div>
            <TableApplication showApp={app === undefined} showServer={true} data={data} />
          </>
        ) : (
          <div className={styles.noStream}>{"Aucun stream en cours ..." /* TRANSLATION */}</div>
        )}
        <br />
        {app ? (
          <>
            <ApplicationNetworkContainer vhost={VHOST} app={app} />
            {multiServer ? (
              <div className={styles.detailsContainer}>
                <Touchable className={styles.detailsSeparator} onPress={() => setShowNetworkDetails(!showNetworkDetails)}>
                  <div style={{ height: "1px", flex: 1, backgroundColor: "var(--color-main-indian-khaki)" }} />
                  <div className={styles.detailsSeparatorTitle}>{showNetworkDetails ? `Hide details ${"▲"}` : `Show details ${"▼"}`}</div>
                  <div style={{ height: "1px", flex: 1, backgroundColor: "var(--color-main-indian-khaki)" }} />
                </Touchable>
                {showNetworkDetails
                  ? contexts.map((c) => {
                      const serverId = c.server._id;
                      if (!serverId) return null;
                      return (
                        <div key={`application_network_details_${serverId}`}>
                          <div className={styles.detailsServer}>{`Server: ${serverId}`}</div>
                          <ApplicationNetworkContainer vhost={VHOST} app={app} serverId={serverId} />
                        </div>
                      );
                    })
                  : null}
              </div>
            ) : null}
            <br />
          </>
        ) : null}

        {providers.length ? (
          <>
            <div className={styles.sectionTitle}>{"Providers (incoming)" /* TRANSLATION */}</div>
            <div className={styles.listContainer}>
              {providers.map((p) => {
                return (
                  <div key={`provider_${p}`} className={styles.listItem}>
                    {p}
                  </div>
                );
              })}
            </div>
          </>
        ) : null}

        {publishers.length ? (
          <>
            <div className={styles.sectionTitle}>{"Publishers (outgoing)" /* TRANSLATION */}</div>
            <div className={styles.listContainer}>
              {publishers.map((p) => {
                return (
                  <div key={`publisher_${p}`} className={styles.listItem}>
                    {p}
                  </div>
                );
              })}
            </div>
          </>
        ) : null}
      </div>
    </PageContainer>
  );
};

export default ApplicationPage;
