import { createAction } from '@reduxjs/toolkit';
const createActionWithServerId = <P = undefined, T extends string = string>(type: T) => createAction<P extends undefined ? { serverId: string} : { serverId: string, data: P }>(type);

export const socketConnect = createAction('socket_connect');
export const socketDisconnect = createAction('socket_disconnect');

// CONTEXT
export interface SocketGetContextParams {
  refresh: boolean,
}
export const socketGetContext = createActionWithServerId<SocketGetContextParams>('socket_get_context');

// STATS
export const socketSubscribeStats = createActionWithServerId('socket_subscribe_stats');
export const socketUnsubscribeStats = createActionWithServerId('socket_unsubscribe_stats');
export const socketGetStats = createActionWithServerId('socket_get_stats');

// SESSIONS
export enum LiveSessionStatus {
  INITIALIZED = 'initialized',
  RUNNING = 'running',
  TERMINATED = 'terminated',
  CANCEL = 'cancel',
}
export interface SocketGetSessionParams {
  serverId?: string,
  vhost?: string,
  app?: string,
  stream?: string,
  search?: string,
  limit?: number,
  skip?: number,
  sortField?: string,
  sort?: 1 | -1,
  status?: LiveSessionStatus | LiveSessionStatus[],
  onlyWithRecords?: boolean,
  onlyWithRedirections?: boolean,
}
export const socketGetLiveSessions = createAction<SocketGetSessionParams>('socket_get_live_sessions');
export const socketGetRecordSessions = createAction<SocketGetSessionParams>('socket_get_record_sessions');


export interface SocketPullStreamParams {
  appName: string,
  streamName: string,
  url: string,
  force?: boolean,
  persistent?: boolean,
  noInputFailoverTimeoutMs?: number,
  unusedStreamDeletionTimeoutMs?: number,
}
export const socketPullStream = createActionWithServerId<SocketPullStreamParams>('socket_pull_stream');

export interface SocketStopStreamParams {
  liveSession?: string,
  app?: string,
  stream?: string,
}
export const socketStopStream = createActionWithServerId<SocketStopStreamParams>('socket_stop_stream');

export interface SocketStartRecordParams {
  liveSession: string,
  tracks?: string[],
}
export const socketStartRecord = createActionWithServerId<SocketStartRecordParams>('socket_start_record');

export interface SocketStopRecordParams {
  recordSession: string,
}
export const socketStopRecord = createActionWithServerId<SocketStopRecordParams>('socket_stop_record');

export interface SocketDeleteRecordParams {
  recordSession: string,
}
export const socketDeleteRecord = createActionWithServerId<SocketStopRecordParams>('socket_delete_record');

export interface SocketAddRedirectionParams {
  liveSession: string,
  url: string,
  key?: string,
  tracks?: string[],
}
export const socketAddRedirection = createActionWithServerId<SocketAddRedirectionParams>('socket_add_redirection');

export interface SocketUploadRecordParams {
  uploadProfile: string,
  recordSession: string,
}
export const socketUploadRecord = createActionWithServerId<SocketUploadRecordParams>('socket_upload_record');

export interface SocketDeleteRedirectionParams {
  redirectionSession: string,
}
export const socketDeleteRedirection = createActionWithServerId<SocketDeleteRedirectionParams>('socket_delete_redirection');


// LOGS
export const socketSubscribeLogs = createActionWithServerId('socket_subscribe_logs');
export const socketUnsubscribeLogs = createActionWithServerId('socket_unsubscribe_logs');
export const socketGetLogs = createActionWithServerId('socket_get_logs');

export default {
  socketConnect,
  socketDisconnect,
  socketGetContext,
  socketSubscribeStats,
  socketUnsubscribeStats,
  socketGetStats,
  socketPullStream,
  socketStopStream,
  socketStartRecord,
  socketStopRecord,
  socketDeleteRecord,
  socketAddRedirection,
  socketUploadRecord,
  socketDeleteRedirection,
  socketGetLiveSessions,
  socketGetRecordSessions,
  socketSubscribeLogs,
  socketUnsubscribeLogs,
  socketGetLogs,
};
