const DEV_ENV_PREFIX = 'REACT_APP_';

export const getEnv = (key: string): string | undefined => {
  let result: string | undefined;
  if (process.env.NODE_ENV === "development") {
    result = process.env[`${DEV_ENV_PREFIX}${key}`];
    if (result !== undefined) {
      return result;
    }
  }
  const customLocalData = (window as any).__custom_local_data__;
  return customLocalData?.[key];
}

export const getEnvApiUrl = () : string | undefined => {
  const urlStr = getEnv('API_URL');
  if (!urlStr) return undefined;
  let url;
  try {
    const urlObj = new URL(urlStr);
    url = urlObj.href;
  } catch {}
  return url;
}

export const getEnvMultiServer = () : boolean | undefined => {
  return getEnv('MULTI_SERVERS') === "true";
}